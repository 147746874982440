.Header {
    width: 100%;
}

#video-background {
    width: 100%;
    height: 100%;
    z-index: -1;
    object-fit: cover;
}

.header-wrapper {
    /* background-color: darkgreen; */
    background-image: url('../Images/arial-trees-still.png');
    /* background-size: 380%; */
    background-position: bottom;
}

.landing-wrapper {
    display: grid;
    height: 80%;
    position: absolute;
    left: 0;
    right: 0;
    z-index: 1;
    height: fit-content;
}

.row-one {
    grid-row: 1;
    padding-top: 8vh;
    display: flex;
    justify-content: space-evenly;
}

.row-one img {
    border-radius: 10%;
    height: 250px;
    width: 250px;
    outline: 1px solid black;
    outline-offset: -5px;
}

.basic-info {
    display: inline-block;
    vertical-align: top;
    color: white;
}

.basic-info h1 {
    font-size: 4.2em;
    margin: 0;
    margin-bottom: 32px;
    padding-top: 5px;
    font-weight: normal;
    text-shadow: -3px 5px 6px #000000c2
}

.basic-info ul {
    width: min-content;
    text-align: left;
    font-size: 1.5em;
    padding: 0;
    text-shadow: -2px 3px 4px #000000c2;
}

.basic-info li {
    list-style-type: none;
    display: flex;
    margin: 5px 0;
}

.basic-info li::before {
    padding-right: 5px;
}

#email {
    margin-bottom: 8px;
}

#email::before {
    content: url('../Images/email-svgrepo-com.svg');
    padding-top: 3px;
}

#linkedin::before {
    content: url('../Images/icons8-linkedin.svg');
    margin-top: -3px;
}

#github::before {
    content: url('../Images/icons8-github.svg');
    margin-top: -4px;
}

#twitter::before {
    content: url('../Images/icons8-twitter.svg');
}

.basic-info a {
    display: contents;
    color: inherit;
}

.basic-info li:hover, a .link-text:hover,
a .link-text:focus {
    text-decoration: underline;
}

.row-two {
    grid-row: 2;
    vertical-align: middle;
    display: flex;
    justify-content: center;
}

.row-two a {
    display: block;
    color: black;
}

.row-two .button-background {
    background-color: #ffffffd9;
    height: 100%;
    margin: 20px 0;
    transition-duration: .3s;
    border-radius: 1px;
}

.row-two .button-background:hover, .round-button:hover,
.row-two .button-background:focus, .round-button:focus {
    background-color: white;
    box-shadow: 0 0 20px 0px #000000b8;
    transition-duration: .3s;
}

.round-button:hover, .round-button:focus {
    background-color: black;
    box-shadow: 0 0 20px 0px #d6d6d6cc;;
}

.socials-menu .round-button:hover,
.socials-menu .round-button:focus {
    box-shadow: 0 0 20px 0px #000000b8;
}

.row-two button {
    /*  */
    padding: 15px 25px;
    background-color: transparent;
    outline: 1px solid black;
    outline-offset: -5px;
    border: none;
    width: 100%;
    font-size: 1.4em;
}

.wrapper {
    display: flex;
    width: 100%;
    position: relative;
}

.round-button {
    border-radius: 50%;
    color: #ffffffd9;
    background-color: black;
    border: none;
    height: 44px;
    width: 44px;
    position: relative;
    margin: 16px 24px;
    border: #ffffff6e 1px solid;
}

#home, .socials {
    position: absolute;
    z-index: 1;
}

#home {
    left: 0;
    background-image: url('../Images/icons8-home.svg');
    background-size: contain
}

.buttons-container {
    position: relative;
    top: 5px;
}

.socials {
    right: 0;
}

.socials-menu {
    display: grid;
}

.socials-menu.collapsed {
    height: 0;
    overflow: hidden;
    animation-name: collapse-menu;
    animation-duration: .3s;
}

.socials-menu .round-button {
    border: unset;
}

.socials-menu.expanded .round-button {
    animation-name: expand-menu;
    animation-duration: .3s;
}

.socials-menu.collapsed .round-button {
    animation-name: collapse-buttons;
    animation-duration: .3s;
}

@keyframes expand-menu {
    0% {
        margin: -28px 0;
        opacity: 0;;
    }
    100% {
        margin: 4px;
    }
}

@keyframes collapse-buttons {
    100% {
        margin: -28px 0;
    }
    0% {
        margin: 0;
    }
}

@keyframes collapse-menu {
    0% {
        overflow: visible;
    }
    100% {
        overflow: visible;
        opacity: 0;
    }
}

#socials-button.round-button {
    background-image: url('../Images/icons8-share.svg');
    background-size: contain
}

.socials-menu button {
    margin: 4px 24px;
}

#email-circle {
    background-image: url('../Images/at-symbol.svg');
    background-size: contain;
    background-color: white;
}

#linkedin-circle {
    background-image: url('../Images/icons8-linkedin-2.svg');
    background-size: contain;
}

#github-circle {
    background-image: url('../Images/icons8-github.svg');
    background-size: contain
}

#twitter-circle {
    background-image: url('../Images/icons8-twitter-2.svg');
    background-size: contain;
}

.link-nav {
    flex-grow: 1;
    word-spacing: 5px;
    color: white;
    font-size: 2em;
    position: relative;
    top: 32px;
}

span.nav-link a {
    color: white;
    font-size: 1.em;
    display: contents;
}

span.nav-link a:hover {
    display: unset;
}

@media screen {
    .landing-page .header-wrapper {
        clip-path: polygon(50% 100%, 0px 50%, 0px 0px, 100% 0px, 100% 50%);
    }

    :not(.landing-page) .header-wrapper {
        clip-path: polygon(50% 100%, 0px 65%, 0px 0px, 100% 0px, 100% 65%);
    }
}

@media screen and (max-width: 700px) {
    .landing-page  .header-wrapper {
        clip-path: polygon(50% 100%, 0px 80%, 0px 0px, 100% 0px, 100% 80%);
    }
    .row-one {
        display: grid;
        padding-top: 20px;
    }

    .row-one img {
        height: 200px;
        width: 200px;
        margin: auto;
    }

    .row-one .basic-info {
        width: 100vw;
    }

    .basic-info h1 {
        font-size: 2.5em;
        margin-bottom: 0;
    }

    .basic-info ul {
        font-size: 1em;
        display: grid;
        width: 100%;
        justify-content: space-evenly;
    }

    .basic-info li {
        margin: 0;
    }

    #github, #twitter {
        grid-row: 2;
    }

    .row-two {
        margin-top: -8%;
    }

    .row-two button {
        font-size: 1.3em;
    }

    .link-nav {
        font-size: 1.5em;
    }
}

@media screen and (min-width: 585px) and (max-width: 729px) {
    .row-one img {
        height: 190px;
        width: 190px;
    }
}

@media screen and (min-width: 1000px) {
    .row-one img {
        height: 290px;
        width: 290px;
    }
}
