.TextBox {
    width: 80vw;
    height: 100%;
    background-color: #ffffffba;;
    border-radius: 8px;
    padding: 10px;
}

.text-content {
    text-align: left;
    color: rgb(65, 65, 65);
    font-size: 1.2em;
}

.text-content p a {
    font-weight: bold;
    color: black;
}

.text-content p a:active {
    font-weight: bold;
    color: white;
    background-color: black;
}

#textBoxContainer img {
    float: left;
    width: 250px;
    height: 300px;
    overflow: hidden;
    margin-right: 27.5px;
    margin: 15px;
    object-fit: cover;
}

@media screen and (max-width: 585px) {
    #textBoxContainer img {
        float: unset;
        height: 250px;
        margin: 15px;
        margin-bottom: 0;
    }

    .text-content {
        font-size: 1em;
    }
}
