#container {
  height: fit-content;
}

.header {
  width: 100%;
  margin-bottom: 15px;
}

/* .text {
  height: 80%;
} */

.TextPage {
  text-align: center;
  position: relative;
  margin-top: 10px;
}

