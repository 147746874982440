@font-face {
  font-family: 'PlayfairDisplay';
  src: local('PlayfairDisplay'), url(./Images/PlayfairDisplay-VariableFont_wght.ttf)
  format('truetype')
}

* {
  font-family: 'PlayfairDisplay';
}

html {
  scroll-behavior: smooth;
}

body {
  min-height: 100vh;
  margin: 0;
  background-image: url('./Images/white-marble.jpg');
  background-size: 400%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root, #container, .ms-Stack {
  min-height: inherit;
}

.ms-Stack {
  height: fit-content;
}

.Footer {
  position: relative;
  bottom: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
