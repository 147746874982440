a.entry-link {
    display: contents;
    -ms-user-select: text;
    -webkit-user-select: text;
    user-select: text;
}

a.entry-link div.container {
    transition-duration: .3s;
}

a.entry-link div.container:hover,
a.entry-link div.container:focus {
    background-color: white;
    box-shadow: 0 0 20px -10px #000000e0;
    transition-duration: .3s;
}

a.entry-link div.container:focus {
    box-shadow: 0 0 20px 0px #000000e0;
    outline: 3px solid black;
}

div.container {
    min-height: 150px;
    width: 100%;
    margin: 15px 0;
    background-color: #ffffffc7;
    border-radius: 8px;
}

div.container img {
    float: left;
    width: 190px;
    height: 190px;
    overflow: hidden;
    margin: 22.5px 15px;
    object-fit: contain;
}

div.container h2 {
    margin-bottom: -10px;
}

div.container p, .container h2 {
    margin: 15px 25px;
    text-align: left;
    color: rgb(65, 65, 65);
}

strong {
    font-size: medium;
    text-decoration: dashed;
}

@media screen and (max-width: 729px) {
    div.container .img-frame {
        float: unset !important;
    }

    div.container .img-frame img {
        float: unset;
        margin-top: 25px;
        margin-bottom: 0;
    }

    div.container {
        width: 90vw;
    }
  }
