.header {
  width: 100%
}

.summaries {
  width: 80%;
}

.summaries div.container p {
  font-size: 18px;
  line-height: 1.5em;
}

@media screen and (max-width: 585px) {
  .summaries div.container p {
    font-size: 17px;
    line-height: unset;
    margin: 15px 20px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}