#container {
  height: -webkit-fill-available;
}

.header {
  width: 100%;
}

.head-text, .entries {
  background: #ffffff8c;
  padding: 0px 24px;
  border-radius: 6px;
}

.head-text {
  width: 80%;
  text-align: left;
}

.head-text h1 {
  font-size: 3em;
  margin: 10px;
}

.head-text p {
  font-size: 15px;
  margin: 10px;
  line-height: 1.25em;
}

.summaries, .entries {
  width: 80%;
  font-size: 16px;
}

.entries h2 {
  font-size: larger;
}

@media screen and (max-width: 359px) {
  .head-text h1 {
    font-size: 1.7em;
  }
}

@media screen and (max-width: 585px) {
  .head-text {
    width: 90vw;
  }

  .head-text h1 {
    text-align: center;
    font-size: 2em;
  }
}
